import React from "react"
import TuDus from "../overview/TuDus"
import FavoriteWishes from "../overview/FavoriteWishes"
import useIsClient from "../../../hooks/useIsClient"
import useUser from "../../../hooks/useUser"
import { Alert } from "@material-ui/lab"
import useGameConfig from "../../../hooks/useGameConfig"
import Layout from "../../layout"

function Favorites() {
  const { isClient } = useIsClient()
  const { user } = useUser()
  const {
    gameConfig: { signedInConfig },
  } = useGameConfig()

  if (!isClient) return null

  const wishes = user.wishes ?? []
  const tudus = user.tudus ?? []

  return (
    <Layout pageName="Tu Du's">
      <div className="favorites">
        {tudus.length > 0 && <TuDus limit={false} />}
      </div>
      <div className="favorites">
        <FavoriteWishes limit={false} />

        {wishes.length === 0 && tudus.length === 0 && (
          <Alert severity="info" className="info-alert space-top-20">
            {signedInConfig.emptyTudusList}
          </Alert>
        )}
      </div>
    </Layout>
  )
}

export default Favorites
