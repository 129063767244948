import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel, FormLabel,
  LinearProgress, RadioGroup,
  TextField
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import useTeam from '../../../hooks/useTeam'
import useGameConfig from '../../../hooks/useGameConfig'
import RadioButtonPublicPrivateGroup from "../../RadioButtonPublicPrivateGroup";

function AddTeamDialog ({ open, handleClose }) {
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(null)
  const { add } = useTeam()
  const { texts: { notificationTexts } } = useGameConfig()


  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const elements = e.target.elements
    const isPrivate = elements.private.value === "private"

    add(elements.newTeamName.value, elements.description.value, "" + isPrivate, setErrorMessage).then(() => {
        handleClose()
        setLoading(false)
      }
    ).catch(() => setLoading(false))
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="new-team-dialog-title"
      aria-describedby="new-team-dialog-description"
    >
      <DialogTitle id="new-team-dialog-title">{notificationTexts.addGroup}</DialogTitle>
      <DialogContent>
        {!loading && <h2>{notificationTexts.addNewGroup}</h2> &&
        <form method="post" id="addTeamForm" onSubmit={handleSubmit} color="primary">
          <TextField
            name="newTeamName"
            autoComplete="off"
            label="Name der Gruppe"
            placeholder={notificationTexts.nameOfGroup}
            required={true}
            fullWidth
            helperText={errorMessage}
            error={errorMessage !== null}
          />
          <TextField
              name="description"
              autoComplete="off"
              label={"Beschreibung"}
              required={true}
              multiline={true}
              fullWidth
              helperText={errorMessage}
              error={errorMessage !== null}
          />
          <RadioButtonPublicPrivateGroup />
        </form>
        }
        {loading && <LinearProgress/>}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" type="submit" color="primary" form="addTeamForm" autoFocus>
          Erstellen
        </Button>
        <Button variant="contained" onClick={handleClose}>
          {notificationTexts.close}
        </Button>
      </DialogActions>
    </Dialog>)
}

export default AddTeamDialog