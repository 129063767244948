import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import useTeam from "../../../hooks/useTeam";
import useGameConfig from "../../../hooks/useGameConfig";



export default function SelectPublicTeamButton ({ team }) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { join } = useTeam()
  const { texts: { others } } = useGameConfig()

  const handleClose = () => {
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const addTeamToPlayer = async (team) =>  {
    try {
      const response = await join(team);
      if(!response.joinTeamMutation.success) {
        throw new Error(`Team Join error: ${response.error}`);
      }
    } catch(error) {
      console.error(`Something went wrong: ${error}`)
    }
  }

  return (
    <>
      <ButtonGroup variant="contained" color="inherit" fullWidth className="button-group">
         <div style={{width:"100%", background: 'white', padding: '0.5em', cursor: 'pointer'}} className="select-team-button" variant="text">
           <strong>{team.name}</strong>
           {/*<div className="limit-text">{team.teammeta.description}</div>*/}
        </div>
        <Button
          onClick={handleToggle}
          style={{background: 'white', maxWidth: '20px !important'}}
          size="small"
          className="arrow-down-button"
          ref={anchorRef}
          variant="text"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={() => addTeamToPlayer(team)}>
                    {others.teamjoin}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
