import SearchBar from "./SearchBar";
import Grid from "@material-ui/core/Grid";
import SelectPublicTeamButton from "./SelectPublicTeamButton";
import React, {useState} from "react";
import useGameConfig from "../../../hooks/useGameConfig";

export default function PublicTeams({teams}) {
    const [searchInput, setSearchInput] = useState(false)
    const { texts: { others } } = useGameConfig()

    const _isInSearch = (teamName) => {
        if(searchInput) {
            return teamName.toLowerCase().includes(searchInput.toLowerCase())
        }
        return true
    }
    return (
        <>
            <h2>{others.teamjoinpublic}</h2>
            <SearchBar searchInputChangedHandler={(value) => setSearchInput(value)}/>
            <br /><br />
            <Grid container className="teams-list">
                {
                    teams?.map(team => {
                            return team && team.teammeta && <Grid item xs={12} key={"public_" + team.id} style={{ marginBottom: '1em' }} hidden={(team.teammeta.visible === "false" || !team.teammeta.visible) || !_isInSearch(team.name)}>
                                    <SelectPublicTeamButton team={team} />
                                </Grid>
                            }
                   )
                }
            </Grid>
        </>
    );
}

