import React from 'react'
import Typography from '@material-ui/core/Typography'
import useUser from '../../../hooks/useUser'
import useIsClient from '../../../hooks/useIsClient'
import {MAX_POINTS, scaleValue} from "../../../utils";
import useGameConfig from '../../../hooks/useGameConfig'

function TotalPoints () {
  const { points } = useUser()
  const { isClient } = useIsClient()
  const scaledPoints = scaleValue(points, [0, MAX_POINTS], [0, 100])
  const { texts: { notificationTexts } } = useGameConfig()

  if (!isClient) return null

  return (
      <>
        <div><h2>{notificationTexts.totalPoints}</h2></div>
        <div  style={{position: "relative", height: '4em', background: 'white'}}>
            <div style={{position: 'absolute', background: '#cad244', height: '100%', width: `${scaledPoints}%` }}>
            </div>
            <div style={{position: 'absolute', right: 0}} className="overview-title">
                {points} / {MAX_POINTS}
            </div>
        </div>
      </>
  )
}

export default TotalPoints