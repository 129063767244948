import React from 'react'
import { navigate } from 'gatsby'
import useUser from '../../hooks/useUser'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { signedIn } = useUser()

  if (!signedIn) {
    // If we’re not logged in, redirect to the home page.
    navigate("/login")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
