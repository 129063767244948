import React from "react"
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import useUser from "../../../hooks/useUser"
import useIsClient from "../../../hooks/useIsClient"
import StarIcon from "@material-ui/icons/Star"
import IconButton from "@material-ui/core/IconButton"
import useGameConfig from "../../../hooks/useGameConfig"

function FavoriteWishes({ limit }) {
  const { user, removeFavoriteWish } = useUser()
  const { isClient } = useIsClient()
  const {
    texts: { notificationTexts },
  } = useGameConfig()

  if (!isClient) return null
  const wishes = user.wishes ?? []

  return (
    <>
      {wishes && wishes.length > 0 && (
        <div className="space-top-20">
          <h2>{notificationTexts.favoriteWishes}</h2>
          <List>
            {wishes.map(
              wish =>
                wish.wishesmeta.visible && (
                  <ListItem
                    button
                    key={wish?.id}
                    className="overview-favorites-list-item"
                    component={Link}
                    to={`/app/sdg/${wish?.wishesmeta?.sdg}#wish_${wish?.databaseId}`}
                    state={{ open: wish?.id }}
                  >
                    <ListItemText
                      id={wish?.id}
                      className="favorites-list-item-text"
                    >
                      <Typography className="overview-accordion-summary">
                        {wish?.wishesmeta?.title}
                      </Typography>
                    </ListItemText>
                    <ListItemSecondaryAction className="favorites-list-unfavorite-button">
                      <IconButton
                        aria-label="remove-favorite"
                        onClick={() => removeFavoriteWish(wish)}
                      >
                        <StarIcon className="favorite-icon filled" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
            )}
          </List>
        </div>
      )}
    </>
  )
}

export default FavoriteWishes
