import React from 'react'
import './sdgs.css'
import { graphql, Link, useStaticQuery } from 'gatsby'
import useUser from '../../../../hooks/useUser'
import '../../../../styles/sdgs.scss'

import Crown from '../../../../../static/images/crown.inline.svg'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import SwiperCore, { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import {GET_WISHES} from "../../../../graphql/queries";
import {useQuery} from "@apollo/client";

SwiperCore.use([Navigation]);

export default function Sdgs () {
  const { user } = useUser()

  const theme = useTheme()
  const screen_md_up = useMediaQuery(theme.breakpoints.up('md'))


  const { allWpSdg } = useStaticQuery(graphql`
    query getTopLevelSdgs {
      allWpSdg(
        sort: {fields: menuOrder, order: ASC}
        filter: {parentDatabaseId: {eq: null}}
      ) {
        nodes {
          id
          title
          slug
          databaseId
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          SdgMeta {
            externeUrl
          }
        }
      }
    }
  `)

  function addScores (sdgs) {
    const updatedSdgs = []

    sdgs.forEach(sdg => {
      sdg.score = 0
      //find children of this SDG
      user.finishedTasks.forEach(task => {
        if (task?.parentDatabaseId === sdg?.databaseId) {
          sdg.score = sdg.score + task.SdgMeta.points
        }
      })

      updatedSdgs.push(sdg)
    })

    return updatedSdgs
  }

  const sdgsWithScores = addScores(allWpSdg.nodes)

  return (
    <div className="multislider">
      <Swiper
        spaceBetween={5}
        slidesPerView={screen_md_up ? 6 : 3}
        freeMode={true}
        navigation
        loop={true}
      >

        {sdgsWithScores.map(node => node.featuredImage &&
          <SwiperSlide key={node.id + node.slug}>

            <div style={{ height: '3em', margin: '0.5em', textAlign: 'center' }}>
              {node.score > 5 && <Crown/>}
            </div>

            <Link to={`/app/sdg/${node.slug}`}>
              <img  src={node.featuredImage.node.localFile.publicURL} alt={node.title}/>
            </Link>

            <div className="sdg-battery-container">
              <svg viewBox="0 0 56.700002 30.299998">
                <g transform="translate(-269 -405.9)">
                  <polygon points="325.4 435.9 325.4 406.2 269.3 406.2 269.3 435.9" fill="#fff"/>
                  <polygon points="325.4 435.9 325.4 406.2 269.3 406.2 269.3 435.9" fill="none" stroke="#cad6e0" strokeWidth=".66"/>
                  <g>
                    <polygon fill={node.score > 0 ? '#c7d400' : '#cad6e0ff'} points="279.4 432.6 279.4 409.5 273 409.5 273 432.6"/>
                    <polygon fill={node.score > 1 ? '#c7d400' : '#cad6e0ff'} points="287.8 432.6 287.8 409.5 281.5 409.5 281.5 432.6"/>
                    <polygon fill={node.score > 2 ? '#c7d400' : '#cad6e0ff'} points="296.3 432.6 296.3 409.5 290 409.5 290 432.6"/>
                    <polygon fill={node.score > 3 ? '#c7d400' : '#cad6e0ff'} points="304.7 432.6 304.7 409.5 298.4 409.5 298.4 432.6"/>
                    <polygon fill={node.score > 4 ? '#c7d400' : '#cad6e0ff'} points="313.2 432.6 313.2 409.5 306.9 409.5 306.9 432.6"/>
                    <polygon fill={node.score > 5 ? '#c7d400' : '#cad6e0ff'} points="321.6 432.6 321.6 409.5 315.3 409.5 315.3 432.6"/>
                  </g>
                </g>
              </svg>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  )
}