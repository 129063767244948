import * as React from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import useGameConfig from "../hooks/useGameConfig";


export default function RadioButtonPublicPrivateGroup() {
  const [value, setValue] = React.useState('private');
  const { texts: { others } } = useGameConfig()

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl style={{marginTop: '2em'}}>
      <FormLabel id="demo-controlled-radio-buttons-group">{others.groupvisibility}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="private"
        defaultValue="private"
        value={value}
        required={true}
        onChange={handleChange}
      >
        <FormControlLabel value="private" control={<Radio />} label="Privat" />
        <FormControlLabel value="public" control={<Radio />} label="Öffentlich" />
      </RadioGroup>
      {value === "public" &&
          <Alert severity="info">{others.publicgroupconfirmation}</Alert>
      }
    </FormControl>
  );
}
