import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {scaleValue} from "../utils";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: '#1a90ff',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

export default function CircularProgressWithLabel(props) {
    const classes = useStyles();
    const size = 60

    const scaledValue = scaleValue(props.value, [0, props.max], [0, 100])

    return (
        <div className={classes.root}>
            <Box position="relative" display="inline-flex">
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={size}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    //disableShrink //this produces error
                    color="secondary"
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={size}
                    thickness={4}
                    value={scaledValue}
                />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                        props.value
                    )}`}</Typography>
                </Box>
            </Box>



        </div>
    );
}
