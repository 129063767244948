import React, { useEffect, useState } from "react";
import Alert from '@material-ui/lab/Alert'
import useUser from '../../../hooks/useUser'
import SelectTeamButton from './SelectTeamButton'
import Button from '@material-ui/core/Button'
import AddTeamDialog from './AddTeamDialog'
import Grid from '@material-ui/core/Grid'
import useGameConfig from '../../../hooks/useGameConfig'
import Layout from '../../layout'
import { useQuery } from "@apollo/client";
import {GET_MY_TEAMS_AND_OTHER_TEAMS_LIGHT_QUERY} from "../../../graphql/queries";
import SearchBar from "./SearchBar";
import PublicTeams from "./PublicTeams";

function Teams () {
  const { user, signedIn, setTeams } = useUser()
  const [addTeamDialogOpen, setAddTeamDialogOpen] = useState(false)
  const { gameConfig: { signedInConfig } } = useGameConfig()
  const { texts: { notificationTexts } } = useGameConfig()
  const { data, startPolling, stopPolling } = useQuery(GET_MY_TEAMS_AND_OTHER_TEAMS_LIGHT_QUERY, {
    variables: { code: user?.code },
    skip: !signedIn,
    ssr: false
  })

  useEffect(() => {
    if (signedIn) {
      startPolling(6000)
    } else {
      stopPolling()
    }

    if(data?.player?.teams?.nodes){
      setTeams(data.player.teams.nodes)
    }
  }, [data])

  const handleAddTeamDialogClose = () => {
    setAddTeamDialogOpen(false)
  }

  return (
    <Layout pageName="Gruppenübersicht">
      <Grid
        container
        alignItems="center"
        className="teams-page"
      >
        <Grid item xs={12} lg={4}>
          <h1>{notificationTexts.groupOverview}</h1>

          <Button
            variant="contained"
            onClick={() => setAddTeamDialogOpen(true)}
            className="space-top-20"
            color="primary"
            fullWidth
          >
            {notificationTexts.addGroup}
          </Button>
        </Grid>
        <Grid item lg={10} xs={12}>
          <h2>{notificationTexts.yourGroups}</h2>
          {
            0 === data?.player?.teams?.nodes?.length ? (
              <Alert severity="info" className="info-alert">
                {signedInConfig.emptyTeamsList}
              </Alert>
            ) : (
              <Grid container className="teams-list">
                {data?.player?.teams?.nodes?.map(team => (
                  <Grid item xs={12} key={team.id} style={{ marginBottom: '1em' }}>
                    <SelectTeamButton team={team}/>
                  </Grid>
                ))}
              </Grid>
            )
          }
          <PublicTeams teams={data?.teams?.nodes} />
          <AddTeamDialog handleClose={handleAddTeamDialogClose} open={addTeamDialogOpen}/>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Teams
