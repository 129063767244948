import React, { useEffect, useState } from "react"
import { GET_TEAM_QUERY } from "../../../graphql/queries"
import { useQuery } from "@apollo/client"
import produce from "immer"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  ListItem,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import Button from "@material-ui/core/Button"
import { graphql, Link, useStaticQuery } from "gatsby"
import Grid from "@material-ui/core/Grid"
import CircularProgressWithLabel from "../../CircularProgressWithLabel"
import HauDenLukas from "./HauDenLukas"
import useUser from "../../../hooks/useUser"
import Hidden from "@material-ui/core/Hidden"
import Layout from "../../layout"
import InviteFriendDialog from "./InviteFriendDialog"
import scrollTo from "gatsby-plugin-smoothscroll"
import useGameConfig from "../../../hooks/useGameConfig"
import { isInProgress } from "../../utils/utils"

function Score({ slug }) {
  const {
    texts: { notificationTexts },
  } = useGameConfig()
  let totalPoints = 0
  const {
    texts: { others },
  } = useGameConfig()

  const [inviteFriendOpen, setInviteFriendOpen] = useState(false)
  const { allWpSdg } = useStaticQuery(graphql`
    query UrlsOfSdgImages {
      allWpSdg(
        filter: { parentId: { eq: null } }
        sort: { fields: menuOrder }
      ) {
        nodes {
          featuredImage {
            node {
              localFile {
                publicURL
                id
              }
            }
          }
          title
          slug
          id
          menuOrder
        }
      }
    }
  `)
  const { points, user } = useUser()
  const { called, loading, data } = useQuery(GET_TEAM_QUERY, {
    variables: { slug },
    pollInterval: 5000,
    fetchPolicy: "network-only",
  })

  const groupPlayerFinishedMissionsAndScores = player => {
    if (!player.playerMeta.finishedTasks) {
      // adding 0 points for players who did not do any tasks
      return produce(player, draft => {
        draft.points = 0
      })
    }

    const groups = []
    player.playerMeta.finishedTasks?.forEach(function (item) {
      const list = groups[item.parent.node.title]

      if (list) {
        list.missions.push(item)
      } else {
        groups[item.parent.node.title] = { missions: [item], points: null }
      }
    })

    for (const sdg in groups) {
      if (groups.hasOwnProperty(sdg)) {
        groups[sdg].points = 0

        groups[sdg].missions.forEach((mission, i) => {
          groups[sdg].points += mission.SdgMeta.points
        })
      }
    }

    return produce(player, draft => {
      draft.points = 0
      draft.groups = groups
      for (const sdg in groups) {
        if (groups.hasOwnProperty(sdg)) {
          draft.points += groups[sdg].points
        }
      }
    })
  }

  const renderPlayerFinishedMissionsAndScores = players => {
    return players.map((player, index) => {
      totalPoints += player?.points

      let sortedSdgs = []
      if (player.groups) {
        sortedSdgs = Object.entries(player.groups).sort(
          (a, b) => a[1].menuOrder - b[1].menuOrder
        )
      }
      const currentPlayer = player?.playerMeta?.nickName === user.nickName

      return (
        <Accordion
          key={player?.playerMeta?.nickName}
          className={currentPlayer ? "current-player" : ""}
          id={currentPlayer ? "current-player" : null}
        >
          <AccordionSummary
            className="player-accordion-summary"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography className="player-nick-name">
              {index + 1}. {player?.playerMeta?.nickName}
            </Typography>
            <CircularProgressWithLabel value={player?.points} max={102} />
          </AccordionSummary>
          <AccordionDetails>
            {player?.points ? (
              <List className="player-accordion-details-sdgs-list">
                {sortedSdgs.map(group => (
                  <ListItem
                    key={group[0]}
                    className="player-accordion-details-sdgs-list-item"
                  >
                    <img src={group[1].imgUrl} alt={group[0]} width={80} />
                    <div className="player-sdg-name">{group[0]}</div>
                    <CircularProgressWithLabel
                      value={group[1].points}
                      max={6}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography className="player-sdg-name">
                {" "}
                {player?.playerMeta?.nickName}{" "}
                {notificationTexts.hasntFinishedAnyTasks}
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      )
    })
  }

  const sortPlayersByScore = players => {
    players.sort((a, b) => b.points - a.points)

    return players
  }

  const attachImagesAndMenuOrder = data => {
    data.map(player => {
      for (const sdg in player.groups) {
        if (player.groups.hasOwnProperty(sdg)) {
          const detectedSdg = allWpSdg.nodes.find(node => node.title === sdg)
          player.groups[sdg].imgUrl =
            detectedSdg?.featuredImage?.node?.localFile.publicURL
          player.groups[sdg].menuOrder = detectedSdg?.menuOrder
        }
      }
    })

    return data
  }

  let groupedPlayersWithFinishedMissionsAndScores = []
  let sortedPlayers = []
  let renderedSortedPlayers = []

  if (data) {
    groupedPlayersWithFinishedMissionsAndScores = data.team.players.nodes.map(
      player => {
        return groupPlayerFinishedMissionsAndScores(player)
      }
    )

    sortedPlayers = sortPlayersByScore(
      groupedPlayersWithFinishedMissionsAndScores
    )

    sortedPlayers = attachImagesAndMenuOrder(sortedPlayers)

    renderedSortedPlayers = renderPlayerFinishedMissionsAndScores(sortedPlayers)
  }

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        scrollTo("#current-player")
      }, 500)
    }
  }, [loading])

  console.log("data?.team?.teammeta", data?.team)
  return (
    <Layout pageName="Score">
      <div className="team-score-page">
        <Grid container spacing={3} justifyContent="center">
          <Hidden smDown>
            <Grid item xs={12} md={4} lg={4}>
              <HauDenLukas score={points} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8} lg={6}>
            <h2>
              Score - Gruppe {data?.team.name ?? ""}
              <Chip
                style={{
                  marginLeft: "1em",
                  background: isInProgress(data?.team)
                    ? "lightgoldenrodyellow"
                    : "white",
                  minWidth: "5em",
                  height: "auto",
                }}
                className="select-team-button"
                label={
                  isInProgress(data?.team)
                    ? "In Bearbeitung"
                    : data?.team?.teammeta?.private === false ||
                      data?.team?.teammeta?.private === null ||
                      data?.team?.teammeta?.private === undefined ||
                      data?.team?.teammeta?.visible === "false" ||
                      data?.team?.teammeta?.visible == undefined ||
                      data?.team?.teammeta?.visible == false ||
                      data?.team?.teammeta?.visible == null
                    ? "Privat"
                    : "Öffentlich"
                }
              />
            </h2>
            {data?.team?.teammeta?.creator !== "false" ? (
              <h3>Gruppenersteller*in: {data?.team?.teammeta?.creator}</h3>
            ) : (
              ""
            )}
            <div style={{ marginBottom: "1em" }}>
              {data?.team?.teammeta?.description}
            </div>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} lg={6} md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setInviteFriendOpen(true)}
                >
                  {others.invitefriend}
                </Button>
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <Button
                  variant="contained"
                  component={Link}
                  to="/app/teams"
                  color="primary"
                >
                  {others.backtoteamoverview}
                </Button>
              </Grid>
            </Grid>
            <br /> <br />
            <Card className="player-accordion-summary">
              <CardContent>
                <Typography className="player-nick-name">
                  <h3>
                    {others.teamtotalscore}: {totalPoints}
                  </h3>
                </Typography>
              </CardContent>
            </Card>
            {called && loading && <LinearProgress />}
            {data && renderedSortedPlayers}
          </Grid>
        </Grid>
      </div>
      <InviteFriendDialog
        state={{ inviteFriendOpen, setInviteFriendOpen, slug: slug }}
      />
    </Layout>
  )
}

export default Score
