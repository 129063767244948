import React, { useState } from 'react'
import { Dialog, DialogTitle, LinearProgress, TextField } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import IconButton from '@material-ui/core/IconButton'
import useTeam from '../../../hooks/useTeam'
import useGameConfig from "../../../hooks/useGameConfig";

function InviteFriendDialog ({ state }) {
  const { inviteFriendOpen, setInviteFriendOpen, slug } = state
  const [playersNickName, setPlayersNickName] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const { invite } = useTeam()
  const { texts: { others } } = useGameConfig()

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)
    invite(playersNickName, slug, setErrorMessage)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const handleClose = () => {
    setInviteFriendOpen(false)
  }

  return (
    <Dialog onClose={handleClose} open={inviteFriendOpen} className="invite-friend-to-group-dialog">
      <DialogTitle>{others.invitefriend}</DialogTitle>
      <form method="post" onSubmit={handleSubmit} color="primary" className="invite-friend-to-group-dialog-form">

        {
          loading ? <LinearProgress/>
            :
            <TextField
              autoComplete="off"
              label="Nickname des Freundes"
              required={true}
              InputProps={{
                endAdornment: (
                  <IconButton type="submit">
                    <PersonAddIcon/>
                  </IconButton>
                ),
              }}
              fullWidth
              onChange={e => {
                setPlayersNickName(e.target.value)
              }}
              helperText={errorMessage}
              error={errorMessage !== null}
              className="invite-friend-to-group-dialog-input"
            />
        }
      </form>
    </Dialog>
  )
}

export default InviteFriendDialog