import React from 'react'
import { Router } from '@reach/router'
import Overview from '../components/app/overview/Overview'
import PrivateRoute from '../components/app/PrivateRoute'
import Favorites from '../components/app/favorites/Favorites'
import Teams from '../components/app/teams/Teams'
import Score from '../components/app/teams/Score'

const App = () => {
  return (
    <Router>
      <PrivateRoute path="/app/overview" component={Overview}/>
      {/* TODO: fix private route: <PrivateRoute path="/app/sdg/:path" component={Sdg}/>*/}
      <PrivateRoute path="/app/teams" component={Teams}/>
      <PrivateRoute path="/app/teams/:slug" component={Score}/>
      <PrivateRoute path="/app/favorites" component={Favorites}/>
    </Router>
  )
}

export default App
