import React from "react"
import TuDus from "./TuDus"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import TotalPoints from "./TotalPoints"
import Sdgs from "./sdgs/sdgs"
import Earth from "../../../../static/images/earth.inline.svg"
import useGameConfig from "../../../hooks/useGameConfig"
import Layout from "../../layout"
import Seo from "../../seo"
import useExternalLinkHandler from "../../../hooks/useExternalLinkHandler"

function Overview({ location }) {
  const {
    menuConfig: { hamburgerMenu },
  } = useGameConfig()
  const {
    texts: { notificationTexts },
  } = useGameConfig()
  const { showExternalLinkConfirmation } = useExternalLinkHandler()

  console.log("isFirstLogin", location)

  return (
    <Layout>
      <Seo title="Mein Überblick" />
      <div className="overview">
        <Grid container>
          <Grid item xs={12}>
            <TotalPoints />
          </Grid>
          <Grid item xs={12}>
            <TuDus limit={true} />
          </Grid>
          <Grid item xs={12}>
            <Sdgs />
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "5em", marginTop: "5em" }}
      >
        <Grid item xs={12} lg={4}>
          <div>
            <Button
              fullWidth
              variant="contained"
              component={Link}
              to="/app/teams"
              color="primary"
            >
              {notificationTexts.groupOverview}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div>
            <Button
              variant="contained"
              fullWidth
              href={hamburgerMenu.linkToSdgs}
              onClick={e => showExternalLinkConfirmation(e, true)}
            >
              {notificationTexts.sdgInformationButton}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Earth />
    </Layout>
  )
}

export default Overview
