import * as React from 'react';
import {TextField} from "@material-ui/core";
import {useState} from "react";


export default function SearchBar({searchInputChangedHandler}) {

  return (
    <>
      <TextField
          defaultValue=""
          fullWidth
          onChange={(event) => {
              const input = event.target.value
              searchInputChangedHandler(input)
          }}
          label="Suche..." />
    </>
  );
}