import React from 'react'
import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { graphql, Link, useStaticQuery } from 'gatsby'
import useUser from '../../../hooks/useUser'
import useIsClient from '../../../hooks/useIsClient'
import StarIcon from '@material-ui/icons/Star'
import IconButton from '@material-ui/core/IconButton'
import useGameConfig from '../../../hooks/useGameConfig'

const getAllTuDus = graphql`
    query getAllTuDus {
        allWpSdg {
            nodes {
                id
                title
                slug
                databaseId
                wpParent {
                    node {
                        slug
                        id
                        databaseId
                    }
                }
            }
        }
    }
`

function TuDus ({ limit }) {
  const { user, removeFavorite } = useUser()
  const { allWpSdg: { nodes } } = useStaticQuery(getAllTuDus)
  const { isClient } = useIsClient()
  const { texts: { notificationTexts } } = useGameConfig()

  if (!isClient) return null

  let myTuDuIds = []
  user.tudus?.forEach(tudu => {
    myTuDuIds.push(tudu.databaseId)
  })

  let myTuDus = myTuDuIds.map(id=>{
    return  nodes.find(node => id === node.databaseId)
  })

  const tudus = limit ? myTuDus.slice(0, 3) : myTuDus

  return (
    <>
      {tudus.length ? <div className="space-top-20">
        <h2>{notificationTexts.tudus}</h2>
        <List>
          {tudus.map(mission => (
            <ListItem
              button
              key={mission.id}
              className="overview-favorites-list-item"
              component={Link}
              to={`/app/sdg/${mission.wpParent.node.slug}#mission_${mission.databaseId}`}
              state={{ open: mission.id }}
            >
              <ListItemText id={mission.id} className="favorites-list-item-text">
                <Typography className="overview-accordion-summary">
                  {mission.title} - {mission.wpParent.node.slug}
                </Typography>
              </ListItemText>
              <ListItemSecondaryAction className="favorites-list-unfavorite-button">
                <IconButton
                  aria-label="remove-favorite"
                  onClick={() => removeFavorite(mission)}
                >
                  <StarIcon className="favorite-icon filled"/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div> : ''
      }
    </>
  )
}

export default TuDus
