import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { navigate } from 'gatsby'
import InviteFriendDialog from './InviteFriendDialog'
import useTeam from '../../../hooks/useTeam'
import useGameConfig from '../../../hooks/useGameConfig'
import {isInProgress} from "../../utils/utils";
import {Chip} from "@material-ui/core";


export default function SelectTeamButton ({ team }) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [inviteFriendOpen, setInviteFriendOpen] = useState(false)
  const { leave } = useTeam()
  const { texts: { notificationTexts } } = useGameConfig()


  const openScorePage = () => {
    navigate(`/app/teams/` + team.slug)
    return null
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openInviteFriendModal = () => {
    setInviteFriendOpen(true)
  }

  const handleLeaveTeamClick = () => {
    leave(team.slug)
    setOpen(false)
  }

  return (
    <>
      <ButtonGroup variant="contained" color="inherit" fullWidth className="button-group">
         <div style={{width:"100%", background: 'white', padding: '0.5em', cursor: 'pointer'}} onClick={openScorePage} className="select-team-button" variant="text">
            <strong>{team.name}</strong>
             {/*<div className="limit-text">{team.teammeta.description}</div>*/}
          </div>
        {isInProgress(team) && <Chip style={{background: 'lightgoldenrodyellow', minWidth: "10em", height: 'auto'}} className="select-team-button" label="In Bearbeitung" />}
        <Button
          style={{background: 'white'}}
          size="small"
          onClick={handleToggle}
          className="arrow-down-button"
          ref={anchorRef}
          variant="text"
        >
          <ArrowDropDownIcon/>
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem onClick={openInviteFriendModal}>
                    {notificationTexts.inviteFriend}
                  </MenuItem>
                  <MenuItem onClick={handleLeaveTeamClick}>
                    {notificationTexts.leaveGroup}
                  </MenuItem>
                  <MenuItem onClick={openScorePage}>
                    {notificationTexts.seeScore}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <InviteFriendDialog state={{ inviteFriendOpen, setInviteFriendOpen, slug: team.slug }}/>
    </>
  )
}
